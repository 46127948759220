






































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";

import { AttendeeSearchResult } from "@/types/interfaces";

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

@Component({
    components: {
        PageTitleHeader,
        AttendeeCard,
        Spinners
    }
})
export default class AttendeeCompanyListing extends Vue {
    @Prop(String)
    id!: string;

    get companyResults() {
        if (attendeeSearchStore.currentCompanyResults) {
            return attendeeSearchStore.currentCompanyResults;
        } else {
            return [];
        }
    }

    get textHeader() {
        return this.$store.getters.getPageOptions("attendeeResults")?.textHeader
            ? this.$store.getters.getPageOptions("attendeeResults").textHeader
            : "Attendee Search Results";
    }

    get isLoading() {
        return !attendeeSearchStore.isCompanyDataLoaded;
    }

    /**
     * Lifecycle hooks
     */
    mounted() {
        if (this.id) {
            attendeeSearchStore.getCompanyResults(this.id);
        }
    }

    /**
     * Methods
     */
    getInitials(result: AttendeeSearchResult) {
        const returnValue = [];
        if (!result) return "";

        if (result?.firstName) {
            returnValue.push(result.firstName.charAt(0));
        }
        if (result?.lastName) {
            returnValue.push(result.lastName.charAt(0));
        }
        return returnValue.join("");
    }

    goToSearch() {
        this.$router.push("/attendees");
    }
}
